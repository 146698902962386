import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { SportEventV2OverviewPageFragmentDoc } from '../fragments/SportEventV2OverviewPage.fragment';
import { SingleStepSportEventFragmentDoc } from '../fragments/SingleStepSportEvent.fragment';
export type GetSingleStepSportOverviewQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	bucketSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	filter?: Types.InputMaybe<Types.SportEventFilter>;
	groupBy: Types.SportEventAggregation;
	platform: Types.Platform;
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	timezoneOffset: Types.Scalars['Int']['input'];
	sortBy?: Types.SportEventSorting;
}>;

export type GetSingleStepSportOverviewQuery = {
	__typename?: 'Query';
	sportEventBucketsV2: {
		__typename?: 'SportEventBucketsConnectionV2';
		edges?: Array<{
			__typename?: 'SportEventBucketsEdgeV2';
			node: {
				__typename?: 'SportEventsConnectionV2';
				totalCount: number;
				edges?: Array<{
					__typename?: 'SportEventsEdgeV2';
					cursor: string;
					node:
						| {
								__typename?: 'MultiStepSportEvent';
								sport: Types.SportType;
								offerCount: number;
								watchNowOffer?: {
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								} | null;
								offers: Array<{
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								}>;
						  }
						| {
								__typename?: 'SingleStepSportEvent';
								sport: Types.SportType;
								id: string;
								objectType: Types.ObjectType;
								objectId: number;
								offerCount: number;
								content: {
									__typename?: 'SingleStepSportEventContent';
									name: string;
									status: Types.SportEventStatus;
									startAt: any;
									endAt?: any | null;
									fullPath?: string | null;
									competitionStage: {
										__typename?: 'SportCompetitionStageV2';
										name: Types.SportCompetitionStageName;
										number?: number | null;
									};
								};
								mysportlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								competition?: {
									__typename?: 'SportCompetitionV2';
									id: string;
									objectId: number;
									sport: Types.SportType;
									activeSeason?: {
										__typename?: 'SportSeason';
										name: string;
										seasonID: number;
									} | null;
									content: {
										__typename?: 'SportCompetitionContentV2';
										name: string;
										fullPath: string;
										iconUrl?: string | null;
									};
								} | null;
								watchNowOffer?: {
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								} | null;
								offers: Array<{
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								}>;
								competitors: {
									__typename?: 'SingleStepSportCompetitorsV2';
									homeTeam: {
										__typename?: 'SingleStepSportCompetitorV2';
										score?: number | null;
										winner: boolean;
										competitors: Array<{
											__typename?: 'SportCompetitorV2';
											id: string;
											objectId: number;
											content: {
												__typename?: 'SportCompetitorContentV2';
												name: string;
												iconUrl?: string | null;
												fullPath?: string | null;
											};
										}>;
									};
									awayTeam: {
										__typename?: 'SingleStepSportCompetitorV2';
										score?: number | null;
										winner: boolean;
										competitors: Array<{
											__typename?: 'SportCompetitorV2';
											id: string;
											objectId: number;
											content: {
												__typename?: 'SportCompetitorContentV2';
												name: string;
												iconUrl?: string | null;
												fullPath?: string | null;
											};
										}>;
									};
								};
						  };
				}> | null;
				pageInfo: {
					__typename?: 'PageInfo';
					startCursor: string;
					endCursor: string;
					hasPreviousPage: boolean;
					hasNextPage: boolean;
				};
			};
			key: {
				__typename?: 'SportEventAggregationKeyV2';
				competitionStage?: {
					__typename?: 'SportCompetitionStageV2';
					name: Types.SportCompetitionStageName;
					number?: number | null;
				} | null;
				competition?: {
					__typename?: 'SportCompetitionV2';
					id: string;
					sport: Types.SportType;
					content: {
						__typename?: 'SportCompetitionContentV2';
						name: string;
						fullPath: string;
						iconUrl?: string | null;
					};
				} | null;
				sport?: { __typename?: 'Sport'; type: Types.SportType } | null;
			};
		}> | null;
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			endCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
		};
	};
};

export const GetSingleStepSportOverviewDocument = gql`
	query GetSingleStepSportOverview(
		$country: Country!
		$language: Language!
		$first: Int!
		$bucketSize: Int
		$filter: SportEventFilter
		$groupBy: SportEventAggregation!
		$platform: Platform!
		$after: String
		$timezoneOffset: Int!
		$sortBy: SportEventSorting! = START_AT
	) {
		...SportEventV2OverviewPage
		sportEventBucketsV2(
			country: $country
			first: $first
			bucketSize: $bucketSize
			filter: $filter
			groupBy: $groupBy
			after: $after
			timezoneOffset: $timezoneOffset
			sortBy: $sortBy
		) {
			edges {
				node {
					edges {
						node {
							...SingleStepSportEvent
						}
					}
				}
			}
		}
	}
	${SportEventV2OverviewPageFragmentDoc}
	${SingleStepSportEventFragmentDoc}
`;
