import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { SingleStepSportEventParticipantsFragmentDoc } from './SingleStepSportEventParticipants.fragment';
import { SportEventOffersV2FragmentDoc } from './SportEventOffersV2.fragment';
export type SingleStepSportEventFragment = {
	__typename?: 'SingleStepSportEvent';
	id: string;
	objectType: Types.ObjectType;
	objectId: number;
	sport: Types.SportType;
	offerCount: number;
	content: {
		__typename?: 'SingleStepSportEventContent';
		name: string;
		status: Types.SportEventStatus;
		startAt: any;
		endAt?: any | null;
		fullPath?: string | null;
		competitionStage: {
			__typename?: 'SportCompetitionStageV2';
			name: Types.SportCompetitionStageName;
			number?: number | null;
		};
	};
	mysportlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	competition?: {
		__typename?: 'SportCompetitionV2';
		id: string;
		objectId: number;
		sport: Types.SportType;
		activeSeason?: { __typename?: 'SportSeason'; name: string; seasonID: number } | null;
		content: { __typename?: 'SportCompetitionContentV2'; name: string; fullPath: string; iconUrl?: string | null };
	} | null;
	competitors: {
		__typename?: 'SingleStepSportCompetitorsV2';
		homeTeam: {
			__typename?: 'SingleStepSportCompetitorV2';
			score?: number | null;
			winner: boolean;
			competitors: Array<{
				__typename?: 'SportCompetitorV2';
				id: string;
				objectId: number;
				content: {
					__typename?: 'SportCompetitorContentV2';
					name: string;
					iconUrl?: string | null;
					fullPath?: string | null;
				};
			}>;
		};
		awayTeam: {
			__typename?: 'SingleStepSportCompetitorV2';
			score?: number | null;
			winner: boolean;
			competitors: Array<{
				__typename?: 'SportCompetitorV2';
				id: string;
				objectId: number;
				content: {
					__typename?: 'SportCompetitorContentV2';
					name: string;
					iconUrl?: string | null;
					fullPath?: string | null;
				};
			}>;
		};
	};
	watchNowOffer?: {
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	} | null;
	offers: Array<{
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	}>;
};

export const SingleStepSportEventFragmentDoc = gql`
	fragment SingleStepSportEvent on SingleStepSportEvent {
		id
		objectType
		objectId
		sport
		content(country: $country, language: $language) {
			name
			status
			startAt
			endAt
			fullPath
			competitionStage {
				name
				number
			}
		}
		mysportlistEntry {
			createdAt
		}
		competition {
			id
			objectId
			sport
			activeSeason(country: $country, language: $language) {
				name
				seasonID
			}
			content(country: $country, language: $language) {
				name
				fullPath
				iconUrl
			}
		}
		...SingleStepSportEventParticipants
		...SportEventOffersV2
	}
	${SingleStepSportEventParticipantsFragmentDoc}
	${SportEventOffersV2FragmentDoc}
`;
