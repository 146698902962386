import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type SingleStepSportEventParticipantsFragment = {
	__typename?: 'SingleStepSportEvent';
	competitors: {
		__typename?: 'SingleStepSportCompetitorsV2';
		homeTeam: {
			__typename?: 'SingleStepSportCompetitorV2';
			score?: number | null;
			winner: boolean;
			competitors: Array<{
				__typename?: 'SportCompetitorV2';
				id: string;
				objectId: number;
				content: {
					__typename?: 'SportCompetitorContentV2';
					name: string;
					iconUrl?: string | null;
					fullPath?: string | null;
				};
			}>;
		};
		awayTeam: {
			__typename?: 'SingleStepSportCompetitorV2';
			score?: number | null;
			winner: boolean;
			competitors: Array<{
				__typename?: 'SportCompetitorV2';
				id: string;
				objectId: number;
				content: {
					__typename?: 'SportCompetitorContentV2';
					name: string;
					iconUrl?: string | null;
					fullPath?: string | null;
				};
			}>;
		};
	};
};

export const SingleStepSportEventParticipantsFragmentDoc = gql`
	fragment SingleStepSportEventParticipants on SingleStepSportEvent {
		competitors {
			homeTeam {
				score
				winner
				competitors {
					id
					objectId
					content(country: $country, language: $language) {
						name
						iconUrl(format: PNG)
						fullPath
					}
				}
			}
			awayTeam {
				score
				winner
				competitors {
					id
					objectId
					content(country: $country, language: $language) {
						name
						iconUrl(format: PNG)
						fullPath
					}
				}
			}
		}
	}
`;
