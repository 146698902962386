import type { WebLocale } from '@/enums/web-locale';
import { Locale } from './locale-helper';

const shortFormattedDate: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: 'short',
};

const longFormattedDate: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: 'long',
};

function formatDate(
	date: Date | null,
	locales: Locale[],
	webLocale: WebLocale,
	timeZone: string | undefined,
	language: string,
	formatType?: 'short' | 'long'
): string {
	if (date == null) return '';

	return new Intl.DateTimeFormat(language, {
		...(formatType === 'long' ? longFormattedDate : shortFormattedDate),
		timeZone: displayTimeZone(locales, webLocale, timeZone),
	})
		.format(date)
		.replace(',', ' -');
}

function formatTime(
	date: Date | null,
	locales: Locale[],
	webLocale: WebLocale,
	timeZone: string | undefined,
	language: string
): string {
	if (date == null) return '';

	return new Intl.DateTimeFormat(language, {
		hour: '2-digit',
		minute: '2-digit',
		timeZone: displayTimeZone(locales, webLocale, timeZone),
	}).format(date);
}

function displayTimeZone(locales: Locale[], webLocale: WebLocale, timeZone: string | undefined): string | undefined {
	if (timeZone != null) return timeZone;

	return locales.find(locale => locale.webLocale === webLocale)?.timezone;
}

export { displayTimeZone, formatDate, formatTime };
