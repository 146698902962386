import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetCompetitionMatchCountQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	filter?: Types.InputMaybe<Types.SearchSportCompetitionFilter>;
	groupBy: Types.SportEventAggregation;
	timezoneOffset: Types.Scalars['Int']['input'];
}>;

export type GetCompetitionMatchCountQuery = {
	__typename?: 'Query';
	sportCompetitionsV2: {
		__typename?: 'SportCompetitionsConnectionV2';
		edges?: Array<{
			__typename?: 'SportCompetitionsEdgeV2';
			cursor: string;
			node: {
				__typename?: 'SportCompetitionV2';
				id: string;
				objectId: number;
				sport: Types.SportType;
				content: { __typename?: 'SportCompetitionContentV2'; name: string; iconUrl?: string | null };
				sportEventBucketsV2: {
					__typename?: 'SportEventBucketsConnectionV2';
					edges?: Array<{
						__typename?: 'SportEventBucketsEdgeV2';
						node: { __typename?: 'SportEventsConnectionV2'; totalCount: number };
					}> | null;
				};
			};
		}> | null;
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			endCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
		};
	};
};

export const GetCompetitionMatchCountDocument = gql`
	query GetCompetitionMatchCount(
		$country: Country!
		$language: Language!
		$first: Int!
		$after: String
		$filter: SearchSportCompetitionFilter
		$groupBy: SportEventAggregation!
		$timezoneOffset: Int!
	) {
		sportCompetitionsV2(country: $country, first: $first, after: $after, filter: $filter) {
			edges {
				cursor
				node {
					id
					objectId
					content(country: $country, language: $language) {
						name
						iconUrl
					}
					sportEventBucketsV2(country: $country, groupBy: $groupBy, timezoneOffset: $timezoneOffset) {
						edges {
							node {
								totalCount
							}
						}
					}
					sport
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
			}
		}
	}
`;
