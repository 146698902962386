
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import PictureComp from '@/components/picture/PictureComp.vue';
import CompetitionLogo from '@/components/sports/common/competition/logos/CompetitionLogo.vue';
import {
	GetCompetitionMatchCountDocument,
	GetCompetitionMatchCountQueryVariables,
} from '@/pages/graphql/queries/GetCompetitionMatchCountV2.query';
import GlobalSportsV2Cards from './GlobalSportsV2Cards.vue';

import { ObjectType, SportType } from '@/@types/graphql-types';
import { SportLocale } from '@/enums/sports';
import { WebLocale } from '@/enums/web-locale';
import { SnowplowSportContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getOverviewUrlBySport } from '@/routing/sports';

const Routing = namespace('routing');

const SPORTS_DIR = `https://www.${JW_CONFIG.DOMAIN}/sports/competition/`;

@Component({
	name: 'GlobalSports',
	components: {
		PictureComp,
		HiddenHorizontalScrollbar,
		GlobalSportsV2Cards,
		CompetitionLogo,
	},
	apollo: {
		competitionMatchCountData: {
			query: GetCompetitionMatchCountDocument,
			fetchPolicy: 'cache-and-network',
			variables(): GetCompetitionMatchCountQueryVariables {
				return this.queryVariables;
			},
			update(data) {
				return data;
			},
			skip() {
				return this.$route.name !== 'app.home';
			},
		},
	},
})
export default class GlobalSports extends Vue {
	competitionMatchCountData: any = {};

	@Prop() language: string;
	@Prop() country: string;
	@Prop() webLocale: WebLocale;

	@Routing.State lastRoute: any;

	get queryVariables() {
		const today = new Date();
		today.setUTCHours(0, 0, 0, 0);

		return {
			country: this.country,
			first: 6,
			after: '',
			filter: {
				activeOn: today.toISOString(),
			},
			language: this.language,
			groupBy: 'SPORT',
			timezoneOffset: 0,
		};
	}

	get competitionMatchCounts() {
		return this.competitionMatchCountData?.sportCompetitionsV2?.edges;
	}

	get leftColumnCompetitions() {
		const first3 = this.competitionMatchCounts?.slice(0, 3);
		return first3?.map((comp: any) => {
			return {
				node: comp?.node,
				id: comp?.node?.id,
				name: comp?.node?.content?.name,
				matchCount: comp?.node?.sportEventBucketsV2?.edges[0]?.node?.totalCount,
				sport: comp?.node?.sport,
			};
		});
	}

	get rightColumnCompetitions() {
		const last3 = this.competitionMatchCounts?.slice(3);
		return last3?.map((comp: any) => {
			return {
				node: comp?.node,
				id: comp?.node?.id,
				name: comp?.node?.content?.name,
				matchCount: comp?.node?.sportEventBucketsV2?.edges[0]?.node?.totalCount,
				sport: comp?.node?.sport,
			};
		});
	}

	getIconSrcset(competitionId: string) {
		return `${SPORTS_DIR}${competitionId}.png`;
	}

	get ASSETS_DIR(): string {
		return `/${ASSETS_DIR}`;
	}

	get sportOrder() {
		switch (this.webLocale) {
			case WebLocale.us:
				return [
					SportType.AmericanFootball,
					SportType.Football,
					SportType.Tennis,
					SportType.FormulaRacing,
					SportType.Baseball,
					SportType.Basketball,
					SportType.IceHockey,
				];
			default:
				return [
					SportType.Football,
					SportType.Tennis,
					SportType.FormulaRacing,
					SportType.AmericanFootball,
					SportType.Baseball,
					SportType.Basketball,
					SportType.IceHockey,
				];
		}
	}

	getFullPath(sport: SportType) {
		return getOverviewUrlBySport(sport, this.webLocale as SportLocale);
	}

	getName(sport: SportType) {
		return this.$t(`WEBAPP_GLOBAL_${sport}`);
	}

	getMatchCount(count: number, sport: SportType) {
		if (sport === SportType.FormulaRacing) {
			// Need to divide F1 races by 5 and round up since each GP comprises of 5 child events
			return this.$t('WEBAPP_GLOBAL_RACES', { count: Math.ceil(count / 5) });
		}

		return this.$t('WEBAPP_GLOBAL_MATCHES', { count });
	}

	async handleSportClick(sport: SportType) {
		const sportContext = new SnowplowSportContext(sport, ObjectType.SportCompetition);
		const pageTypeContext = await TrackingHelper.getPageTypeContext(this.lastRoute.path, true);

		TrackingHelper.trackEvent('userinteraction', { action: 'sport_clicked', useLastRoute: true }, [
			sportContext,
			pageTypeContext,
		]);
	}
}
