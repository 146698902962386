
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PictureComp from '@/components/picture/PictureComp.vue';

import { SportCompetitionV2 } from '@/@types/graphql-types';

@Component({ name: 'CompetitionLogo', components: { PictureComp } })
export default class CompetitionLogo extends Vue {
	@Prop({ required: true }) competition: SportCompetitionV2;

	get imageUrl() {
		return this.competition?.content?.iconUrl;
	}

	get competitionName() {
		return this.competition?.content?.name;
	}

	getImageUrl(url: string) {
		return Vue.filter('Thumbor')({ url, imageType: 'competitor_icon' });
	}

	get fallbackSrc() {
		return this.competition?.sport
			? `/${ASSETS_DIR}/img/sports/icons/sport/${this.competition?.sport?.toLowerCase()}.png`
			: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
	}
}
