import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type ToggleMySportsNotificationMutationVariables = Types.Exact<{
	input: Types.SetInTitleListInput;
}>;

export type ToggleMySportsNotificationMutation = {
	__typename?: 'Mutation';
	setInMysportlist: {
		__typename?: 'SetInMysportlistPayload';
		title:
			| {
					__typename?: 'MultiStepSportEvent';
					id: string;
					competition?: { __typename?: 'SportCompetitionV2'; id: string } | null;
					mysportlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| {
					__typename?: 'SingleStepSportEvent';
					id: string;
					competition?: { __typename?: 'SportCompetitionV2'; id: string } | null;
					mysportlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string };
	};
};

export const ToggleMySportsNotificationDocument = gql`
	mutation ToggleMySportsNotification($input: SetInTitleListInput!) {
		setInMysportlist(input: $input) {
			title {
				id
				... on SingleStepSportEvent {
					competition {
						id
					}
					mysportlistEntry {
						createdAt
					}
				}
				... on MultiStepSportEvent {
					competition {
						id
					}
					mysportlistEntry {
						createdAt
					}
				}
			}
		}
	}
`;
