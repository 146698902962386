
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';

import { ObjectType, Platform, SportType } from '@/@types/graphql-types';
import { SportLocale } from '@/enums/sports';
import { SnowplowSportContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import Provider from '@/interfaces/provider';
import {
	GetSportProvidersByShortNamesDocument,
	GetSportProvidersByShortNamesQuery,
	GetSportProvidersByShortNamesQueryVariables,
} from '@/pages/graphql/queries/GetSportProvidersByShortNames.query';
import { getOverviewUrlBySport } from '@/routing/sports';

const Constant = namespace('constant');
const Language = namespace('language');

@Component({
	name: 'GlobalSportsV2HeaderCard',
	components: {
		HiddenHorizontalScrollbar,
		ProviderIcon,
	},
	apollo: {
		sportProvidersByShortName: {
			query: GetSportProvidersByShortNamesDocument,
			variables(): GetSportProvidersByShortNamesQueryVariables {
				return this.queryVariables;
			},
			update(data: GetSportProvidersByShortNamesQuery) {
				return data.packages.map(({ shortName }) => shortName);
			},
			skip() {
				return !this.$route.name?.startsWith('app.home');
			},
		},
	},
})
export default class GlobalSportsV2HeaderCard extends Vue {
	sportProvidersByShortName: string[] = [];

	@Prop() name: string;
	@Prop() sportIcon: string;
	@Prop() sport: SportType;

	@Constant.Getter providersByShortName: Record<string, Provider>;
	@Language.Getter country: string;
	@Language.State webLocale: SportLocale;

	get queryVariables(): GetSportProvidersByShortNamesQueryVariables {
		return {
			country: this.country,
			platform: Platform.Web,
		};
	}

	get overviewFullPath() {
		return getOverviewUrlBySport(this.sport, this.webLocale as SportLocale);
	}

	get providers() {
		const sportProviders = this.sportProvidersByShortName
			.map(name => this.providersByShortName[name])
			.filter(provider => provider != null);

		return sportProviders;
	}

	get sportName() {
		return this.$t(`WEBAPP_GLOBAL_${this.sport}`);
	}

	handleSeeAllClick(event: any) {
		const sportContext = new SnowplowSportContext(this.sport, ObjectType.Sport);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'sport_clicked',
				property: 'card-v2',
			},
			[sportContext]
		);
	}
}
