import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetSportProvidersByShortNamesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	platform: Types.Platform;
}>;

export type GetSportProvidersByShortNamesQuery = {
	__typename?: 'Query';
	packages: Array<{ __typename?: 'Package'; packageId: number; shortName: string; clearName: string }>;
};

export const GetSportProvidersByShortNamesDocument = gql`
	query GetSportProvidersByShortNames($country: Country!, $platform: Platform!) {
		packages(country: $country, platform: $platform, hasSport: true) {
			packageId
			shortName
			clearName
		}
	}
`;
