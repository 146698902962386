
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';

import BasicButton from '@/ui-components/BasicButton.vue';
import { SportEventStatus } from '@/@types/graphql-types';

import ToggleMySportsNotificationMutationMixin from '@/mixins/ToggleMySportsNotificationMutation.vue';
import { ToggleMySportsNotificationPayload } from '@/enums/sports';

@Component({
	name: 'NoOfferNotifier',
	components: { BasicButton },
})
export default class NoOfferNotifier extends Mixins(ToggleMySportsNotificationMutationMixin) {
	@Prop() eventStatus: SportEventStatus;
	@Prop({ default: true }) showLabel: boolean;
	@Prop({ required: true }) notifyMePayload: ToggleMySportsNotificationPayload;

	get isNotificationActive() {
		return this.notifyMePayload.isNotificationActive;
	}

	get buttonLabel() {
		if (this.isEnded) {
			return this.$t('SPORTS_WEBAPP_NOTIFY_WHEN_HL_AVAIL');
		}

		return this.isNotificationActive
			? this.$t('SPORTS_WEBAPP_NOTIFICATIONACTIVE')
			: this.$t('SPORTS_WEBAPP_NOTIFY_WHEN_AVAIL');
	}

	get isEnded() {
		return [SportEventStatus.Ended, SportEventStatus.Closed].includes(this.eventStatus);
	}

	@Emit('click')
	async notifyMe() {
		!this.isEnded && (await this.toggleMySportsNotification(this.notifyMePayload));
	}
}
