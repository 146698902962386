
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ObjectType, SportEventStatus, SportOffer } from '@/@types/graphql-types';

import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import CompetitorLogo from '@/components/sports/common/CompetitorLogo.vue';
import NoOfferNotifier from '@/components/sports/common/event/NoOfferNotifier.vue';
import GameEventStatus from '@/components/sports/common/chip/GameEventStatus.vue';

import { SportLocale, ToggleMySportsNotificationPayload } from '@/enums/sports';
import { Locale } from '@/helpers/locale-helper';
import { ClickoutUrl } from '@/helpers/clickout-helper';
import { BuyboxParam, trackClickOutSports } from '@/helpers/offer-helper';
import { formatDate, formatTime } from '@/helpers/time-zone-helper';
import { SnowplowSportContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { UpcomingEvent } from '@/interfaces/sport-graphql';
import ToggleMySportsNotificationMutationMixin from '@/mixins/ToggleMySportsNotificationMutation.vue';

const Language = namespace('language');
const Routing = namespace('routing');
const User = namespace('user');

@Component({
	name: 'MultiStepEventEncounter',
	components: {
		ProviderIcon,
		CompetitorLogo,
		NoOfferNotifier,
		GameEventStatus,
	},
})
export default class MultiStepEventEncounter extends Mixins(ToggleMySportsNotificationMutationMixin) {
	@Prop() country: string;
	@Prop() domainSessionId: string;
	@Prop() listIndex: number;
	@Prop() event: UpcomingEvent;
	@Prop({ default: false }) isGlobalPage: boolean;
	@Prop({ default: false }) withCompetitionInHeader: boolean;

	@Language.Getter language: string;
	@Language.State locales: Locale[];
	@Language.State webLocale: SportLocale;

	@Routing.State lastRoute: any;

	@User.State timeZone: string | undefined;

	get componentContainer() {
		if (this.eventPath) {
			return {
				cmp: 'router-link',
				props: {
					to: this.eventPath,
				},
			};
		}
		return {
			cmp: 'div',
		};
	}

	get sportContext() {
		return SnowplowSportContext.fromSportEventV2(this.event.sport, ObjectType.SportEvent, this.event.sportEvent);
	}

	get formattedLineItemNames() {
		if (this.isTennisDoubles) {
			return [
				{
					text: `${this.shortName(this.event?.lineItems[0]?.text)} / ${this.shortName(
						this.event?.lineItems[1]?.text
					)}`,
				},
				{
					text: `${this.shortName(this.event?.lineItems[2].text)} / ${this.shortName(
						this.event?.lineItems[3]?.text ?? this.event?.lineItems[2].text
					)}`,
				},
			];
		} else {
			return this.event?.lineItems;
		}
	}

	get sport() {
		return this.event?.sport;
	}

	get isTennisDoubles() {
		return this.sport === 'TENNIS' && this.event?.lineItems.length > 2;
	}

	get eventStatus() {
		return this.event?.sportEvent?.content?.status;
	}

	get isLive() {
		return [SportEventStatus.Live].includes(this.eventStatus);
	}

	get isEnded() {
		return [SportEventStatus.Ended, SportEventStatus.Closed].includes(this.eventStatus);
	}

	get eventPath() {
		const path = { path: this.event.fullPath, query: {} };
		// for SEO purposes to prevent google from indexing the URL with the stage parameter.
		if (process.client) {
			const { name, number } = this.event.sportEvent.content?.competitionStage;
			path.query = { stage: `${name} ${number || ''}` };
		}
		return path;
	}

	get isNotUpcoming() {
		return [
			SportEventStatus.Cancelled,
			SportEventStatus.Closed,
			SportEventStatus.Delayed,
			SportEventStatus.Ended,
			SportEventStatus.Live,
			SportEventStatus.Postponed,
			SportEventStatus.Suspended,
		].includes(this.eventStatus);
	}

	get watchNowOffer() {
		return this.event.watchNowOffer;
	}

	get notifyMePayload(): ToggleMySportsNotificationPayload {
		return {
			eventId: this.event.id,
			isNotificationActive: !!this.event.mysportlistEntry?.createdAt,
		};
	}

	shortName(name: string) {
		if (!name) return '';

		const firstNameInital = name.split('')[0];
		const surname = name.split(' ').slice(1).join(' ');

		return `${surname} ${firstNameInital}`;
	}

	formatDate(ts: Date) {
		return formatDate(ts, this.locales, this.webLocale, this.timeZone, this.language);
	}
	formatTime(ts: Date) {
		return formatTime(ts, this.locales, this.webLocale, this.timeZone, this.language);
	}

	getOfferClickoutUrl(offer: SportOffer) {
		return new ClickoutUrl(offer)
			.set('uct_country', this.country)
			.set('uct_buybox', BuyboxParam.Sport)
			.set('sid', this.domainSessionId)
			.set('uct_sport', this.event?.sport)
			.toString();
	}

	handleClickOut() {
		if (this.watchNowOffer == null) return;

		trackClickOutSports(this.watchNowOffer, 0, this.listIndex, this.watchNowOffer.sportObjectType, 'card-v2', [
			this.sportContext,
		]);
	}

	handleCardClick() {
		if (this.isGlobalPage) return;
		TrackingHelper.trackEvent(
			'userinteraction',
			{ action: 'event_clicked', property: 'card-v2', useLastRoute: true },
			[this.sportContext]
		);
	}

	get isNotificationActive() {
		return !!this.event.mysportlistEntry?.createdAt;
	}

	async handleNoOfferNotifierClick() {
		TrackingHelper.trackEvent(
			'userinteraction',
			{ action: 'notify_me_clicked', property: 'card-v2', useLastRoute: true },
			[this.sportContext]
		);
	}

	async goToCompetitionPage(competitionFullPath: string) {
		this.$router.push({ path: competitionFullPath });
		// tracking
		const sportContext = new SnowplowSportContext(this.event.sport, ObjectType.SportCompetition);
		const pageTypeContext = await TrackingHelper.getPageTypeContext(this.lastRoute.path, true);

		TrackingHelper.trackEvent('userinteraction', { action: 'competition_clicked', property: 'card-v2' }, [
			sportContext,
			pageTypeContext,
		]);
	}
}
