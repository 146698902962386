import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type SportEventOffersV2_MultiStepSportEvent_Fragment = {
	__typename?: 'MultiStepSportEvent';
	offerCount: number;
	watchNowOffer?: {
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	} | null;
	offers: Array<{
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	}>;
};

export type SportEventOffersV2_SingleStepSportEvent_Fragment = {
	__typename?: 'SingleStepSportEvent';
	offerCount: number;
	watchNowOffer?: {
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	} | null;
	offers: Array<{
		__typename?: 'SportOffer';
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		audioLanguage?: string | null;
		standardWebURL?: string | null;
		sportObjectType: Types.SportObjectType;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			technicalName: string;
			clearName: string;
			monetizationTypes: Array<Types.MonetizationType>;
		};
	}>;
};

export type SportEventOffersV2Fragment =
	| SportEventOffersV2_MultiStepSportEvent_Fragment
	| SportEventOffersV2_SingleStepSportEvent_Fragment;

export const SportEventOffersV2FragmentDoc = gql`
	fragment SportEventOffersV2 on SportEventV2 {
		offerCount(country: $country, platform: $platform, filter: { bestOnly: true })
		watchNowOffer(country: $country, platform: $platform) {
			package {
				id
				packageId
				technicalName
				clearName
				monetizationTypes
			}
			monetizationType
			presentationType
			audioLanguage
			standardWebURL
			sportObjectType
		}
		offers(country: $country, platform: $platform, filter: { bestOnly: true }) {
			package {
				id
				packageId
				technicalName
				clearName
				monetizationTypes
			}
			monetizationType
			presentationType
			audioLanguage
			standardWebURL
			sportObjectType
		}
	}
`;
